import { fabric } from 'fabric'

export const zoomInAndOut = (opt, drawCanvas) => {
  opt.e.preventDefault()

  // Prevent unnecessary updates if zoom value doesn't change
  const delta = opt.e.deltaY
  let zoom = drawCanvas.getZoom()
  let newZoom = zoom * 0.999 ** delta

  if (newZoom > 10) newZoom = 10
  if (newZoom < 0.1) newZoom = 0.1

  if (newZoom !== zoom) {
    drawCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, newZoom)
  }
}

export const panMouseDown = (opt) => {
  // set isPanning
  return opt.e.altKey ? true : false
}

export const panMouseMove = (opt, isPanning, drawCanvas) => {
  if (!isPanning) return
  const e = opt.e
  const delta = new fabric.Point(e.movementX, e.movementY)
  drawCanvas.relativePan(delta)
}

export const panMouseUp = () => {
  // set isPanning to false
  return false
}

export const viewReset = (drawCanvas) => {
  drawCanvas.setZoom(1)
  drawCanvas.absolutePan({ x: 0, y: 0 })
}
