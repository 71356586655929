import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, useGLTF } from '@react-three/drei'
/* eslint-disable react/prop-types */
const Model = ({ url }) => {
  const { scene } = useGLTF(url)
  return <primitive object={scene} />
}

const ThreeDViewport = ({ modelUrl }) => {
  return (
    <Canvas style={{ width: '100%', height: '100%' }} camera={{ position: [0, 2, 5], fov: 75 }}>
      <ambientLight intensity={1} />
      <OrbitControls />
      {modelUrl && <Model url={modelUrl} />}
    </Canvas>
  )
}

export default ThreeDViewport
