import React, { useState } from 'react'
import { Modal, Input, Button, Tooltip } from 'antd'
import promptSuggestions from '../data/wheel_prompts.json'
/* eslint-disable react/prop-types */

const WheelMaskOptions = ({ visible, onClose, onGenerate }) => {
  const [wheelMaskPrompt, setWheelMaskPrompt] = useState('')
  const [currentPromptPage, setCurrentPromptPage] = useState(1)
  const promptsPerPage = 6 // Adjusted for two-column layout

  const startIndex = (currentPromptPage - 1) * promptsPerPage
  const endIndex = startIndex + promptsPerPage
  const currentPrompts = promptSuggestions.prompts.slice(startIndex, endIndex)

  return (
    <Modal
      title={<h2 className="text-2xl font-bold text-blue-600">Wheel Options</h2>}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          onClick={onClose}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold rounded transition-all duration-300"
        >
          Cancel
        </Button>,
        <Button
          key="generate"
          type="primary"
          onClick={() => onGenerate(wheelMaskPrompt)}
          disabled={!wheelMaskPrompt}
          className={`${
            wheelMaskPrompt
              ? 'bg-gradient-to-r from-blue-500 to-purple-500 hover:from-purple-500 hover:to-blue-500'
              : 'bg-gray-300 cursor-not-allowed'
          } text-white font-semibold rounded transition-all duration-300`}
        >
          Generate
        </Button>,
      ]}
      width="70%"
      className="rounded-xl shadow-2xl transform transition-transform" // Rounded corners for the modal container
      bodyStyle={{ borderRadius: '1rem', padding: '1.5rem' }} // Rounded corners for the modal body
    >
      <Input.TextArea
        rows={4}
        value={wheelMaskPrompt}
        onChange={(e) => setWheelMaskPrompt(e.target.value)}
        placeholder="Enter your prompt here..."
        className="text-sm p-3 rounded border-2 border-gray-300 focus:ring-2 focus:ring-blue-400 focus:outline-none transition-all duration-300"
      />
      <div className="prompt-suggestions grid grid-cols-2 gap-4 mt-6">
        {currentPrompts.map((suggestion, index) => (
          <Tooltip key={index} title={suggestion.description} placement="top">
            <Button
              onClick={() => setWheelMaskPrompt(suggestion.description)}
              className="p-3 text-sm bg-gradient-to-r from-sky-100 to-sky-200 hover:from-sky-200 hover:to-sky-300 text-blue-800 font-medium border border-transparent rounded-lg shadow-md hover:shadow-lg transition-all duration-300 flex justify-center items-center"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <span className="inline-block w-full text-center">
                {suggestion.description.length > 30
                  ? `${suggestion.description.slice(0, 30)}...`
                  : suggestion.description}
              </span>
            </Button>
          </Tooltip>
        ))}
      </div>
      <div className="pagination-buttons flex justify-between mt-6">
        <Button
          disabled={currentPromptPage === 1}
          onClick={() => setCurrentPromptPage(currentPromptPage - 1)}
          className={`${
            currentPromptPage === 1
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-400 hover:to-gray-500 text-gray-800'
          } font-semibold rounded-lg px-4 py-2 transition-all duration-300`}
        >
          Previous
        </Button>
        <Button
          disabled={endIndex >= promptSuggestions.prompts.length}
          onClick={() => setCurrentPromptPage(currentPromptPage + 1)}
          className={`${
            endIndex >= promptSuggestions.prompts.length
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-400 hover:to-gray-500 text-gray-800'
          } font-semibold rounded-lg px-4 py-2 transition-all duration-300`}
        >
          Next
        </Button>
      </div>
    </Modal>
  )
}

export default WheelMaskOptions
