import React, { Suspense, useEffect, useState, useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls, Html, useGLTF, SoftShadows, Environment } from '@react-three/drei'
import * as THREE from 'three'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUI } from '../../contexts/UIContext'
import { useFullscreen } from '../../contexts/screen-context'
import { getFromDB, clearDB } from '../../core/indexedDB'
import './Studio.css'
import { BulbOutlined, BgColorsOutlined, BorderOutlined, FileOutlined, CloudUploadOutlined } from '@ant-design/icons'
import { ChromePicker } from 'react-color'
import { Popover, Upload, Tooltip, Spin } from 'antd'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter'
import ImagePreview from '../editPage/ImagePreview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

/* eslint-disable react/prop-types */
const Loader = () => (
  <Html center>
    <h3>Loading Model...</h3>
  </Html>
)

const Model = ({ url, position, isWireframe, setMinY, modelMinY, podiumHeight = 0.1, modelRef }) => {
  const { scene } = useGLTF(url)

  const [modelHeight, setModelHeight] = useState(0)
  const [adjustedY, setAdjustedY] = useState(0) // State for adjusted Y position

  useEffect(() => {
    // Calculate bounding box only once when the model is loaded
    const box = new THREE.Box3().setFromObject(scene)
    const minY = box.min.y // Minimum Y value of the model
    const height = box.max.y - box.min.y // Height of the model
    setMinY(minY) // Update the minimum Y value in the parent component
    setModelHeight(height) // Update the model height
  }, [scene, setMinY])

  useEffect(() => {
    // Apply wireframe mode to the model's material
    scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true
        child.material.wireframe = isWireframe // Apply the isWireframe prop
      }
    })
  }, [scene, isWireframe]) // Reapply wireframe mode when isWireframe changes

  useEffect(() => {
    // Recalculate adjusted Y position after modelMinY or modelHeight changes
    setAdjustedY(-modelMinY + podiumHeight) // Align model base with podium top
  }, [modelHeight, modelMinY, podiumHeight])

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.001 // Rotate the model slowly
    }
  })

  return (
    <primitive
      ref={modelRef}
      object={scene}
      position={[position[0], adjustedY, position[2]]} // Use adjusted Y position
      scale={1}
    />
  )
}

const Podium = ({ position, podiumHeight = 0.05 }) => {
  const textureLoader = new THREE.TextureLoader()

  // Load textures for the podium
  const marbleTexture = textureLoader.load('/textures/laminate_floor_02_diff_4k.jpg') // Marble texture
  const normalMap = textureLoader.load('/textures/asphalt_06_ao_4k.jpg') // Normal map texture

  return (
    <mesh position={[position[0], podiumHeight / 2, position[2]]} castShadow receiveShadow>
      <cylinderGeometry args={[1.45, 1.5, podiumHeight, 256]} />
      <meshStandardMaterial
        map={marbleTexture}
        normalMap={normalMap}
        roughness={0.2}
        metalness={0.8}
        envMapIntensity={1.5}
        transparent={false}
        opacity={1}
      />
    </mesh>
  )
}

const Studio = () => {
  const [isFullscreen, setIsFullscreen] = useState(true)
  const [position, setPosition] = useState([0, 0, 0])
  const [isWireframe, setIsWireframe] = useState(false)
  const [lightIntensity, setLightIntensity] = useState(10)
  const [lightColor, setLightColor] = useState('#ffffff')
  const [modelMinY, setModelMinY] = useState(0)
  const navigate = useNavigate()
  const { setShowSidebar } = useUI()
  const { setIsFullscreen: setFullscreenContext, setMargin } = useFullscreen()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [modelUrl, setModelUrl] = useState(queryParams.get('modelUrl') || '/models/msrt.glb')
  const [hdrUrl, setHdrUrl] = useState('/environment/default_environment.hdr')
  const [toneMapping, setToneMapping] = useState(THREE.ReinhardToneMapping)
  const [toneMappingExposure, setToneMappingExposure] = useState(1.5)
  const modelRef = useRef()
  const [previewImage, setPreviewImage] = useState(null)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [showImageWidget, setShowImageWidget] = useState(false)
  const { images: stateImages = [], progress = 0 } = location.state || {}
  const [images, setImages] = useState(
    stateImages.length > 0 ? stateImages : JSON.parse(localStorage.getItem('images') || '[]'),
  )
  const visibleCount = 3

  const handleClosePreview = () => {
    setPreviewImage(null) // Close the modal
    setCurrentImageIndex(0) // Reset the index
  }

  const handleDownloadImage = () => {
    if (images[currentImageIndex]) {
      const link = document.createElement('a')
      link.href = images[currentImageIndex].src // Assuming `src` contains the image URL
      link.download = `image-${currentImageIndex + 1}.jpg`
      link.click()
    }
  }

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const storedImages = await getFromDB('StudioDB', 'Images', 'images')
        if (storedImages) {
          console.log('Fetched images:', storedImages.data)
          setImages(storedImages.data)
          setShowImageWidget(storedImages.data.length > 0)
        }
      } catch (err) {
        console.error('Failed to retrieve images from IndexedDB:', err)
      }
    }

    fetchImages()
  }, [])

  useEffect(() => {
    setShowSidebar(false)
    setFullscreenContext(true)
    setMargin('canvas')

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsFullscreen((prev) => !prev)
      }
      if (event.key === 'ArrowUp') {
        setPosition((prev) => [prev[0], prev[1] + 0.1, prev[2]])
      }
      if (event.key === 'ArrowDown') {
        setPosition((prev) => [prev[0], prev[1] - 0.1, prev[2]])
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      setShowSidebar(true)
      setFullscreenContext(false)
    }
  }, [setShowSidebar, setFullscreenContext, setMargin])

  useEffect(() => {
    const loader = new RGBELoader()
    loader.load(hdrUrl, (texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping // Set the correct mapping
      setHdrUrl(texture) // Store the loaded texture in state
      console.log('Default environment loaded:', texture)
    })
  }, [])

  const applyEnvironment = (hdrUrl) => {
    const loader = new RGBELoader()
    loader.load(hdrUrl, (texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping // Set the correct mapping
      setHdrUrl(texture) // Store the loaded texture in state
      console.log('Default environment loaded:', texture)
    })
  }

  const exportScene = () => {
    if (!modelRef.current) {
      console.error('Model reference is not set or invalid.')
      return
    }

    const exporter = new GLTFExporter()

    // Export the model (or the entire scene)
    exporter.parse(
      modelRef.current, // The object to export (e.g., the model or scene)
      (result) => {
        if (result instanceof ArrayBuffer) {
          // If exporting as binary (.glb)
          const blob = new Blob([result], { type: 'application/octet-stream' })
          const url = URL.createObjectURL(blob)

          // Create a download link
          const link = document.createElement('a')
          link.href = url
          link.download = 'model-export.glb' // Change to .gltf if needed
          link.click()

          // Clean up
          URL.revokeObjectURL(url)
        } else {
          // If exporting as JSON (.gltf)
          const output = JSON.stringify(result, null, 2)
          const blob = new Blob([output], { type: 'application/json' })
          const url = URL.createObjectURL(blob)

          // Create a download link
          const link = document.createElement('a')
          link.href = url
          link.download = 'model-export.gltf'
          link.click()

          // Clean up
          URL.revokeObjectURL(url)
        }
      },
      (error) => {
        console.error('An error occurred during export:', error)
      },
      { binary: true }, // Set to true for .glb, false for .gltf
    )
  }

  const scrollUp = () => {
    document.querySelector('.overflow-y-auto').scrollBy({ top: -100, behavior: 'smooth' }) // Scrolls up
  }

  const scrollDown = () => {
    document.querySelector('.overflow-y-auto').scrollBy({ top: 100, behavior: 'smooth' }) // Scrolls down
  }

  const handleImageClick = (src, index) => {
    // Assuming setPreviewImage is the state setter for displaying the modal
    console.log('Image clicked:', src)
    console.log('Image index:', index)
    setCurrentImageIndex(index)
    setPreviewImage(src)
  }

  const handleCloseWidget = async () => {
    try {
      const result = await clearDB('StudioDB', 'Images')
      console.log(result) // Logs: "Database cleared successfully"
    } catch (error) {
      console.error('Failed to clear database:', error)
    }
    setShowImageWidget(false) // State handler to toggle visibility
    setImages([])
  }

  return (
    <div className={`studio-container ${isFullscreen ? 'full-screen' : ''}`}>
      <div className="fixed top-12 left-1/2 transform -translate-x-1/2 flex gap-1 z-50">
        {/* Default Environment */}
        <Tooltip title="Default Environment" placement="bottom">
          <button
            onClick={() => applyEnvironment('/environment/default_environment.hdr')}
            className="w-6 h-6 bg-gradient-to-br from-blue-500 to-green-500 rounded-md hover:opacity-80 transition"
            title="Default Environment"
          />
        </Tooltip>

        {/* Studio Environment */}
        <Tooltip title="Studio Environment" placement="bottom">
          <button
            onClick={() => applyEnvironment('/environment/photo_studio_broadway_hall_4k.hdr')}
            className="w-6 h-6 bg-gradient-to-br from-purple-500 to-pink-500 rounded-md hover:opacity-80 transition"
            title="Studio Environment"
          />
        </Tooltip>

        {/* Modern Environment */}
        <Tooltip title="Sunset Environment" placement="bottom">
          <button
            onClick={() => applyEnvironment('/environment/modern_buildings_4k.hdr')}
            className="w-6 h-6 bg-gradient-to-br from-orange-500 to-yellow-500 rounded-md hover:opacity-80 transition"
            title="Modern Environment"
          />
        </Tooltip>

        {/* Industry Environment */}
        <Tooltip title="Night Environment" placement="bottom">
          <button
            onClick={() => applyEnvironment('/environment/industrial_workshop_foundry_4k.hdr')}
            className="w-6 h-6 bg-gradient-to-br from-gray-700 to-black rounded-md hover:opacity-80 transition"
            title="Industry Environment"
          />
        </Tooltip>

        {/* Shop Environment */}
        <Tooltip title="Forest Environment" placement="bottom">
          <button
            onClick={() => applyEnvironment('/environment/machine_shop_02_4k.hdr')}
            className="w-6 h-6 bg-gradient-to-br from-green-700 to-green-400 rounded-md hover:opacity-80 transition"
            title="Shop Environment"
          />
        </Tooltip>

        {/* Autoshop Environment */}
        <Tooltip title="Desert Environment" placement="bottom">
          <button
            onClick={() => applyEnvironment('/environment/autoshop_01_4k.hdr')}
            className="w-6 h-6 bg-gradient-to-br from-yellow-500 to-orange-300 rounded-md hover:opacity-80 transition"
            title="Autoshop Environment"
          />
        </Tooltip>

        {/* Aero Environment */}
        <Tooltip title="Ocean Environment" placement="bottom">
          <button
            onClick={() => applyEnvironment('/environment/aerodynamics_workshop_4k.hdr')}
            className="w-6 h-6 bg-gradient-to-br from-blue-400 to-blue-700 rounded-md hover:opacity-80 transition"
            title="Aero Environment"
          />
        </Tooltip>

        {/* Street Environment */}
        <Tooltip title="Ocean Environment" placement="bottom">
          <button
            onClick={() => applyEnvironment('/environment/wide_street_02_4k.hdr')}
            className="w-6 h-6 bg-gradient-to-br from-gray-400 to-blue-700 rounded-md hover:opacity-80 transition"
            title="Street Environment"
          />
        </Tooltip>
      </div>
      {!isFullscreen && (
        <button className="back-button" onClick={() => navigate(-1)}>
          ←
        </button>
      )}

      <div className="fixed top-2 right-2 w-[300px] grid grid-cols-2 gap-4 bg-gray-100 text-gray-800 p-4 rounded-lg border shadow-lg z-50 transition-all duration-300 pointer-events-auto">
        <h3 className="col-span-2 text-lg font-bold text-center">Studio Controls</h3>

        <div className="col-span-2 text-xs text-gray-500">Use ↑ / ↓ keys to move model up/down</div>

        <div className="col-span-1">
          <label className="text-sm font-medium">
            <BulbOutlined className="mr-2" /> Light Intensity:
          </label>
          <input
            type="range"
            min="0.5"
            max="3"
            step="0.1"
            value={lightIntensity}
            onChange={(e) => setLightIntensity(parseFloat(e.target.value))}
            className="w-full"
          />
        </div>

        <div className="col-span-1">
          <label className="text-sm font-medium flex items-center gap-2">
            <BgColorsOutlined className="mr-2 text-blue-500" /> Light Color:
          </label>
          <Popover
            content={
              <ChromePicker
                color={lightColor}
                onChange={(color) => setLightColor(color.hex)}
                disableAlpha
                styles={{
                  default: {
                    picker: {
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                      borderRadius: '8px',
                    },
                  },
                }}
              />
            }
            trigger="click"
            placement="bottom"
          >
            <div
              className="mt-2 w-full h-5 rounded-md border border-gray-300 cursor-pointer"
              style={{
                backgroundColor: lightColor,
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            />
          </Popover>
        </div>

        <div className="col-span-2">
          <label className="text-sm font-medium">
            <FileOutlined className="mr-2" /> Tone Mapping:
          </label>
          <div className="col-span-1">
            <label className="text-sm font-medium">Exposure:</label>
            <input
              type="range"
              min="0.1"
              max="5"
              step="0.1"
              value={toneMappingExposure}
              onChange={(e) => setToneMappingExposure(parseFloat(e.target.value))}
              className="w-full"
            />
          </div>
          <Tooltip title="Select Tone Mapping" placement="top">
            <select
              className="w-full border rounded-md p-1 bg-white text-sm"
              value={toneMapping}
              onChange={(e) => setToneMapping(Number(e.target.value))} // Update tone mapping state
            >
              <option value={THREE.LinearToneMapping}>Linear</option>
              <option value={THREE.ReinhardToneMapping}>Reinhard</option>
              <option value={THREE.CineonToneMapping}>Cineon</option>
              <option value={THREE.ACESFilmicToneMapping}>ACES Filmic</option>
            </select>
          </Tooltip>
        </div>

        <div className="col-span-1">
          <label className="text-sm font-medium">
            <BorderOutlined className="mr-2" /> Wireframe:
          </label>
          <button
            onClick={() => setIsWireframe((prev) => !prev)}
            className="w-full px-3 py-1 bg-gray-200 text-black border border-gray-300 rounded-md hover:bg-gray-300 transition flex items-center gap-2 text-sm"
          >
            {isWireframe ? 'Disable' : 'Enable'}
          </button>
        </div>

        <div className="col-span-1 flex gap-2">
          <button className="w-full px-3 py-2 bg-green-500 text-white text-sm rounded hover:bg-green-600">🕶️ AR</button>
          <button className="w-full px-3 py-2 bg-purple-500 text-white text-sm rounded hover:bg-purple-600"></button>
        </div>
        <div className="col-span-2 flex gap-4 items-center">
          <div className="flex-1">
            <Tooltip
              title="Upload a 3D model file (GLB, OBJ, STEP)"
              placement="bottom"
              overlayInnerStyle={{
                borderRadius: '8px', // Rounded corners
                backgroundColor: 'rgba(68, 68, 68, 0.7)', // Transparent background
                color: '#fff', // White text
                padding: '8px', // Add padding for better spacing
              }}
            >
              <Upload
                accept=".glb,.gltf,.obj,.step"
                showUploadList={false}
                beforeUpload={(file) => {
                  const allowedExt = ['.glb', '.gltf', '.obj', '.step']
                  const ext = file.name.slice(file.name.lastIndexOf('.')).toLowerCase()

                  if (!allowedExt.includes(ext)) {
                    alert('Unsupported model format. Please upload a GLB, OBJ, or STEP file.')
                    return false
                  }

                  const url = URL.createObjectURL(file)
                  setModelUrl(url) // Update the state with the new model URL
                  return false // Prevent default upload behavior
                }}
              >
                <button className="w-full px-3 py-1 bg-gray-200 text-black border border-gray-300 rounded-md hover:bg-gray-300 transition flex items-center gap-2 text-sm">
                  <CloudUploadOutlined /> Model
                </button>
              </Upload>
            </Tooltip>
          </div>

          <div className="flex-1">
            <Tooltip
              title="Upload an HDR environment file"
              placement="bottom"
              overlayInnerStyle={{
                borderRadius: '8px', // Rounded corners
                backgroundColor: 'rgba(80, 80, 80, 0.7)', // Transparent background
                color: '#fff', // White text
                padding: '8px', // Add padding for better spacing
              }}
            >
              <Upload
                accept=".hdr"
                showUploadList={false}
                beforeUpload={(file) => {
                  if (!file.name.toLowerCase().endsWith('.hdr')) {
                    alert('Please upload a .hdr file.')
                    return false
                  }

                  const reader = new FileReader()
                  reader.onload = () => {
                    const loader = new RGBELoader()
                    loader.load(reader.result, (texture) => {
                      texture.mapping = THREE.EquirectangularReflectionMapping // Set the correct mapping
                      setHdrUrl(texture) // Store the loaded texture in state
                      console.log('Environment texture loaded:', texture)
                    })
                  }
                  reader.readAsDataURL(file) // Read the file as a data URL
                  return false // Prevent default upload behavior
                }}
              >
                <button className="w-full px-3 py-1 bg-gray-200 text-black border border-gray-300 rounded-md hover:bg-gray-300 transition flex items-center gap-2 text-sm">
                  <CloudUploadOutlined /> HDR
                </button>
              </Upload>
            </Tooltip>
          </div>
          <div className="col-span-1">
            <button
              onClick={exportScene}
              className="w-full px-3 py-2 bg-blue-500 text-white text-sm rounded hover:bg-blue-600"
            >
              Export Model
            </button>
          </div>
        </div>
      </div>

      <Canvas
        shadows
        camera={{ position: [0, 3, 3], fov: 50 }}
        gl={(gl) => {
          gl.toneMapping = toneMapping // Apply the selected tone mapping
          gl.toneMappingExposure = toneMappingExposure // Adjust exposure (optional)
          console.log('Tone Mapping:', gl.toneMapping)
          console.log('Exposure:', gl.toneMappingExposure)
        }}
      >
        <SoftShadows size={2} samples={8} />
        <Podium position={position} modelMinY={modelMinY} podiumHeight={0.05} />
        <ambientLight intensity={2} />
        <directionalLight
          position={[0, 100, 0]}
          receiveShadow
          intensity={lightIntensity}
          castShadow
          color={lightColor}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
        />
        <pointLight position={[-5, 10, -5]} intensity={lightIntensity} color={lightColor} />
        <spotLight position={[0, 4, 4]} angle={0.3} penumbra={0.5} intensity={10} castShadow={false} />
        <spotLight position={[0, 4, -4]} angle={0.3} penumbra={0.5} intensity={20} castShadow={false} />
        <spotLight position={[-4, 4, 0]} angle={0.3} penumbra={0.5} intensity={20} castShadow={false} />
        <spotLight position={[4, 4, 0]} angle={0.3} penumbra={0.5} intensity={10} castShadow={false} />

        <group>
          <Suspense fallback={<Loader />}>
            <Model
              url={modelUrl}
              position={position}
              isWireframe={isWireframe}
              setMinY={setModelMinY}
              modelMinY={modelMinY}
              podiumHeight={0.05}
              modelRef={modelRef}
            />
            {hdrUrl && <Environment map={hdrUrl} background />} {/* Use the loaded HDR texture */}
          </Suspense>
        </group>

        <OrbitControls
          enablePan={true}
          enableZoom={true}
          enableRotate={true}
          minPolarAngle={Math.PI / 4}
          maxPolarAngle={Math.PI / 1.4}
          zoomSpeed={0.6}
          rotateSpeed={0.4}
        />
      </Canvas>

      {showImageWidget && (
        <div
          className="fixed top-2 left-2 bg-gray-100 p-2 rounded-lg border-2 shadow-lg z-50 transition-all duration-300 pointer-events-auto"
          style={{ width: '200px', maxHeight: '80vh' }}
        >
          <div className="flex justify-between items-center">
            <h2 className="text-base font-bold rounded-lg bg-gray-200 p-2">Generated Images</h2>
            <button onClick={handleCloseWidget} className="text-xl p-1">
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>
          <div className="relative items-center justify-center overflow-hidden mt-4">
            {images.length > visibleCount && (
              <button className="relative top-0 left-0 text-black" onClick={scrollUp} style={{ zIndex: 20 }}>
                <FontAwesomeIcon icon={faChevronUp} />
              </button>
            )}
            <div className="overflow-y-auto" style={{ maxHeight: 'calc(70vh - 40px)' }}>
              <div className="grid grid-cols-1 gap-4">
                {images.map((img, index) => (
                  <div
                    key={index}
                    className="relative w-full flex items-center justify-center overflow-hidden rounded-lg"
                    style={{ height: '150px', border: '1px solid #ccc' }} // Set fixed height here
                  >
                    {img.loading ? (
                      <div className="relative w-full h-full flex items-center justify-center overflow-hidden rounded-lg">
                        <img
                          src="./car.jpg" // Path to a sample blurred image
                          className="absolute top-0 left-0 w-full h-full object-cover blur-moving"
                          alt="Loading image"
                        />
                        <div className="absolute inset-0 flex justify-center items-center">
                          <Spin size="large" />
                        </div>
                      </div>
                    ) : (
                      <img
                        src={img.src}
                        alt={`Generated Image ${index}`}
                        className="w-full h-full object-cover rounded-lg hover:scale-105 shadow-xl transition-all duration-300 ease-in-out cursor-pointer"
                        onClick={() => handleImageClick(img.src, index)}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            {images.length > visibleCount && (
              <button
                className="relative centre bottom-0 text-black shadow-lg"
                onClick={scrollDown}
                style={{ zIndex: 10 }}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </button>
            )}

            {previewImage && (
              <ImagePreview
                images={images}
                currentIndex={currentImageIndex}
                onCancel={handleClosePreview}
                onDownload={handleDownloadImage}
                setCurrentIndex={setCurrentImageIndex}
                progress={progress}
                setPreviewImage={setPreviewImage}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Studio
