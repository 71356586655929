import React, { useCallback } from 'react'
import { Slider } from 'antd'
/* eslint-disable react/prop-types */

const ThreeDTab = ({ get3DWeight, set3DWeight }) => {
  return (
    <div>
      <div className="justify-between items-center">
        <div>
          <label className="block text-sm font-medium">Denoise:</label>
          <Slider
            tooltip={{ placement: 'top' }}
            step={0.1}
            max={1}
            min={0}
            value={get3DWeight}
            onChange={useCallback(
              (v) => {
                set3DWeight(v)
              },
              [set3DWeight],
            )}
            marks={{
              0: {
                style: { fontSize: '10px' },
                label: 'min',
              },
              1: {
                style: { fontSize: '10px' },
                label: 'max',
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ThreeDTab
