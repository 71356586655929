import { fabric } from 'fabric'
// import { MAX_HEIGHT, MAX_WIDTH } from '../constant'
import { updateFabricCanvas, clearPreviousElements } from './lassoInteraction'

export const handleImageUpload = (e, drawCanvas, setOriginImage, setInpaintSnapShots) => {
  const file = e.target.files[0]
  if (!file) return

  const fileUrl = URL.createObjectURL(file)
  fabric.Image.fromURL(fileUrl, (img) => {
    const canvasWidth = img.width
    const canvasHeight = img.height

    // Set canvas dimensions explicitly
    drawCanvas.setWidth(canvasWidth)
    drawCanvas.setHeight(canvasHeight)
    drawCanvas.getElement().width = canvasWidth
    drawCanvas.getElement().height = canvasHeight

    // Align image to the top-left corner of the canvas
    img.set({
      left: 0,
      top: 0,
      selectable: false,
      hasControls: false,
      evented: false,
      hoverCursor: 'default',
    })

    // Update the state with the new image and snapshot
    setOriginImage(img)
    setInpaintSnapShots([{ originImage: img }])
  })
}

const dataURLToBlob = (dataurl) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

const getMaskUrl = (drawCanvas) => {
  const prevBack = drawCanvas.backgroundImage
  drawCanvas.setBackgroundColor('rgb(0,0,0)', drawCanvas.renderAll.bind(drawCanvas))
  drawCanvas.setBackgroundImage(new fabric.Image(''), drawCanvas.renderAll.bind(drawCanvas))
  const maskUrl = drawCanvas.toDataURL('image/jpeg')
  drawCanvas.setBackgroundColor('rgb(255,255,255)', drawCanvas.renderAll.bind(drawCanvas))
  drawCanvas.setBackgroundImage(prevBack, drawCanvas.renderAll.bind(drawCanvas))
  return maskUrl
}

export const handleImageDownload = (inpaintImage) => {
  const inpaintUrl = inpaintImage.toDataURL({ format: 'image/jpeg' })
  const e = new MouseEvent('click')
  let b = document.createElement('a')
  b.download = 'inpaint.jpg'
  b.href = inpaintUrl
  b.dispatchEvent(e)
}

export const handleDownloadPreview = (src) => {
  const e = new MouseEvent('click')
  let b = document.createElement('a')
  b.download = 'inpaint.jpg'
  b.href = src
  b.dispatchEvent(e)
}

const getMaskImageData = (drawCanvas) => {
  console.log('getMaskImageData', drawCanvas)

  const prevBack = drawCanvas.backgroundImage

  // Temporarily set the canvas background to black
  drawCanvas.setBackgroundColor('rgb(0,0,0)', drawCanvas.renderAll.bind(drawCanvas))
  drawCanvas.setBackgroundImage(null, drawCanvas.renderAll.bind(drawCanvas))

  const canvas = drawCanvas.getElement()
  const context = canvas.getContext('2d')

  // Ensure the canvas dimensions are correct
  console.log(`Canvas Dimensions: ${canvas.width}x${canvas.height}`)

  const imageData = context.getImageData(0, 0, canvas.width, canvas.height).data

  // Restore the original canvas background
  drawCanvas.setBackgroundColor('rgb(255,255,255)', drawCanvas.renderAll.bind(drawCanvas))
  drawCanvas.setBackgroundImage(prevBack, drawCanvas.renderAll.bind(drawCanvas))

  return imageData
}

export const isMaskDrawn = (drawCanvas) => {
  const imageData = getMaskImageData(drawCanvas)

  console.log('Mask Image Data:', imageData)

  for (let i = 0; i < imageData.length; i += 4) {
    // Check if any pixel is not black (indicating a mask is drawn)
    if (imageData[i] !== 0 || imageData[i + 1] !== 0 || imageData[i + 2] !== 0) {
      console.log('Mask is drawn.')
      return true
    }
  }

  console.log('No mask is drawn.')
  return false
}

export const getInpaintFormData = (drawCanvas, originImage, useRef, referenceImage) => {
  let param = new FormData()
  const maskUrl = getMaskUrl(drawCanvas)
  param.append('mask', dataURLToBlob(maskUrl), 'mask.jpg')
  const originUrl = originImage.toDataURL('image/jpeg')
  param.append('origin', dataURLToBlob(originUrl), 'origin.jpg')

  if (useRef) {
    param.append('reference', referenceImage, 'reference.jpg')
  }
  return param
}

export const getDeblurFormData = (originImage) => {
  let param = new FormData()
  const originUrl = originImage.toDataURL('image/jpeg')
  param.append('origin', dataURLToBlob(originUrl), 'origin.jpg')
  return param
}

let maskImages = {}

function processMaskImage(maskImg, originalWidth, originalHeight, callback) {
  const tempCanvas = document.createElement('canvas')
  const tempCtx = tempCanvas.getContext('2d')

  // Set the canvas dimensions to match the original image
  tempCanvas.width = originalWidth
  tempCanvas.height = originalHeight

  // Draw the mask image onto the canvas, resizing it to match the original dimensions
  tempCtx.drawImage(maskImg, 0, 0, originalWidth, originalHeight)

  // Create a new image from the resized canvas
  const resizedMaskImg = new Image()
  resizedMaskImg.src = tempCanvas.toDataURL()

  resizedMaskImg.onload = () => {
    callback(resizedMaskImg)
  }
}

export const applyMaskOverImage = (drawCanvas, maskUrl, controlPoints, opacity = 0.3, setLassos, setActiveIndex) => {
  const maskImg = new Image()
  maskImg.crossOrigin = 'Anonymous' // Handle CORS issues
  maskImg.src = maskUrl

  const originalWidth = drawCanvas.getWidth()
  const originalHeight = drawCanvas.getHeight()

  maskImg.onload = () => {
    processMaskImage(maskImg, originalWidth, originalHeight, (processedImg) => {
      const fabricImg = new fabric.Image(processedImg, {
        left: 0,
        top: 0,
        evented: true,
        hoverCursor: 'move',
        opacity: opacity,
      })

      maskImages[maskUrl] = fabricImg

      console.log('Mask applied to canvas.')

      // Update lassos
      setLassos((prevLassos) => {
        const newLassos = [...prevLassos, controlPoints]
        const newIndex = { lassoIndex: newLassos.length - 1, pointIndex: -1 }
        setActiveIndex(newIndex)
        updateFabricCanvas(drawCanvas, newLassos, newIndex)
        return newLassos
      })
    })
  }
}

export const adjustMaskImage = (drawCanvas, maskUrl, properties) => {
  const maskImg = maskImages[maskUrl]
  if (maskImg) {
    maskImg.set(properties)
    drawCanvas.renderAll()
  }
}

export const removeMaskImage = (drawCanvas, setLassos, setActiveIndex) => {
  setLassos((prevLassos) => {
    const newLassos = [...prevLassos]
    const lassoIndex = newLassos.length - 1

    if (lassoIndex >= 0) {
      clearPreviousElements(drawCanvas, lassoIndex) // Remove all elements related to the lasso
      newLassos.pop() // Remove the last lasso

      const newIndex = { lassoIndex: -1, pointIndex: -1 }
      setActiveIndex(newIndex)
      updateFabricCanvas(drawCanvas, newLassos, newIndex)

      return newLassos
    }

    return prevLassos
  })
}
