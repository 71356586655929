import React, { useCallback, useContext, useMemo, useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fabric } from 'fabric'
import axios from 'axios'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import {
  faEraser,
  faDownload,
  faEye,
  faUpload,
  faShare,
  faReply,
  faDrawPolygon,
  faHand,
  faRotate,
  faArrowLeft,
  faArrowRight,
  faCodeCompare,
  faWandMagicSparkles,
  faGem,
  faPlay,
  faSpinner,
  faTimesCircle,
  faChevronUp,
  faChevronDown,
  faTimes,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FileImageOutlined } from '@ant-design/icons'
import { Slider, message, Spin, Tooltip, Switch } from 'antd'
import { FabricContext } from './EditPage'
import ThreeDViewport from './ThreeDViewport'
import { DRAW_TYPE, originSnapShot } from './constant'
import { saveToDB, clearDB } from '../../core/indexedDB'
import {
  handleImageUpload,
  handleImageDownload,
  handleDownloadPreview,
  getInpaintFormData,
  getDeblurFormData,
  isMaskDrawn,
  applyMaskOverImage,
  removeMaskImage,
} from './fabricFunc/imageTransport'
import { viewReset } from './fabricFunc/zoomAndPan'
import {
  undoCommand,
  redoCommand,
  restoreSnapShot,
  canUndo,
  canRedo,
  storeInpaintSnapShots,
  restoreInpaintSnapShot,
} from './fabricFunc/fabricSnapShots'
import InputButton from './InputButton'
import ToolButton from './ToolButton'
import ImagePreview from './ImagePreview'
import RichDropdown from './RichDropdown'
import RenderTab from './promptWidget/RenderTab'
import RefineTab from './promptWidget/RefineTab'
import ThreeDTab from './promptWidget/ThreeDTab'
import { inpaintAPI, deblurAPI, processAPI, detectAPI } from './service'
import { makeAuthorizedRequest, uploadToSignedUrl } from '../../core/api'
import './image-widget.css'
import { styles } from './options'
import partLabels from './data/mask_map.json'
import WheelMaskOptions from './promptWidget/WheelOptions'
import { useProject } from '../../contexts/project-context'
import { useAuth } from '../../contexts/auth-context'

const ToolBar = () => {
  const navigate = useNavigate()
  const { idToken } = useAuth()
  const [promptText, setPromptText] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [isImageUploading, setImageIsUploading] = useState(false)
  const [activeTab, setActiveTab] = useState('render')
  const [drawingInfluence, setDrawingInfluence] = useState(4)
  const [denoise, setDenoise] = useState(60 / 100)
  const [numberOfImages, setNumberOfImages] = useState(1)
  const [showImageWidget, setShowImageWidget] = useState(false)
  const [images, setImages] = useState([])
  const [previewImage, setPreviewImage] = useState(null)
  const [style, setStyle] = useState('surface')
  const [upscaleFactor, setUpscaleFactor] = useState(1)
  const [useReferenceImage, setUseReferenceImage] = useState(false)
  const [referenceImage, setReferenceImage] = useState(null)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [progress] = useState(0)
  const [ipWeight, setIPWeight] = useState(1)
  const [changeBackgroundOnly, setChangeBackgroundOnly] = useState(false)
  const [changeSelectedAreaOnly, setChangeSelectedAreaOnly] = useState(true)
  const [blob, setBlob] = useState(null)
  const [labels, setLabels] = useState([])
  const [selectedTabs, setSelectedTabs] = useState([])
  const tabsContainerRef = useRef()
  const [isWheelMaskSelected, setIsWheelMaskSelected] = useState(false)
  const [isWheelMaskModalVisible, setIsWheelMaskModalVisible] = useState(false)
  const { currentProject } = useProject()
  const { projectId } = useParams()
  const [get3DWeight, set3DWeight] = useState(0.7)
  const [isGenerating3D, setIsGenerating3D] = useState(false)

  const {
    drawCanvas,
    originImage,
    setOriginImage,
    drawType,
    setDrawType,
    penWidth,
    setPenWidth,
    setLassos,
    setActiveIndex,
    snapShots,
    setSnapShots,
    snapShotsID,
    setSnapShotsID,
    inpaintSnapShots,
    setInpaintSnapShots,
    inpaintSnapShotsID,
    setInpaintSnapShotsID,
    setIsLoading,
    setShowOriginImage,
    viewMode,
    setViewMode,
    modelUrl,
  } = useContext(FabricContext)

  const drawingInfluenceMax = 15
  const drawingInfluenceMin = 2
  const creativityMax = 95
  const creativityMin = 50
  const weightMin = 1
  const weightMax = 2

  const clearCanvas = useCallback(() => {
    const fabricObjects = drawCanvas.current.getObjects()
    for (let i = 0; i < fabricObjects.length; i++) {
      drawCanvas.current.remove(fabricObjects[i])
    }
    setLassos([])
    setActiveIndex({ lassoIndex: -1, pointIndex: -1 })
    setDrawType(DRAW_TYPE.NORMAL)
    setSnapShots(originSnapShot)
    setSnapShotsID(0)
    setSelectedTabs([])
  }, [drawCanvas, setActiveIndex, setDrawType, setLassos, setSnapShots, setSnapShotsID])

  const visibleCount = 3

  const handleImageClick = (src, index) => {
    // Assuming setPreviewImage is the state setter for displaying the modal
    setCurrentImageIndex(index)
    setPreviewImage(src)
  }

  const onDrop = useCallback((acceptedFiles) => {
    setReferenceImage(acceptedFiles[0])
    const file = acceptedFiles[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type })
        setBlob(blob)
      }
      reader.readAsArrayBuffer(file)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  })

  const handleAddImageToCanvas = useCallback(
    (currentImage) => {
      const src = currentImage.src || (referenceImage && URL.createObjectURL(referenceImage))

      setPreviewImage(null)
      setCurrentImageIndex(0)
      setIsWheelMaskSelected(false)
      if (src) {
        setImageIsUploading(true) // Start loading
        const imgElement = new Image()
        imgElement.src = src
        imgElement.onload = async () => {
          const newImage = new fabric.Image(imgElement, {
            left: 0,
            top: 0,
            selectable: false,
            hasControls: false,
            evented: false,
            hoverCursor: 'default',
          })

          const canvas = drawCanvas.current
          const canvasWidth = imgElement.width
          const canvasHeight = imgElement.height
          canvas.setWidth(canvasWidth)
          canvas.setHeight(canvasHeight)

          console.log(`Image and canvas dimensions set to: ${canvasWidth}x${canvasHeight}`)

          clearCanvas()
          canvas.add(newImage)
          storeInpaintSnapShots(
            newImage,
            inpaintSnapShots,
            inpaintSnapShotsID,
            setInpaintSnapShots,
            setInpaintSnapShotsID,
          )
          setOriginImage(newImage)

          // Convert image to binary blob
          const response = await fetch(src)
          const blob = await response.blob()

          // Prepare formData for API call
          const formData = new FormData()
          formData.append('image', blob, 'image.png')

          // Call the API
          axiosInstance
            .post(detectAPI, formData)
            .then(async (res) => {
              const { label_mask_mapping, visualization } = res.data

              const masksMapping = label_mask_mapping.map(({ mask, label, control_points }) => {
                const binaryString = atob(mask)
                const len = binaryString.length
                const bytes = new Uint8Array(len)
                for (let i = 0; i < len; i++) {
                  bytes[i] = binaryString.charCodeAt(i)
                }
                const maskBlob = new Blob([bytes], { type: 'image/png' })
                return { [partLabels.masks[label]]: URL.createObjectURL(maskBlob), control_points }
              })

              console.log('masksMapping-->:', masksMapping)

              const binaryString = atob(visualization)
              const len = binaryString.length
              const bytes = new Uint8Array(len)
              for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i)
              }
              const visualizationBlob = new Blob([bytes], { type: 'image/png' })
              const visualizationUrl = URL.createObjectURL(visualizationBlob)

              setLabels(masksMapping.map((item) => item))
              console.log('labels--->', labels)
              console.log('selected label--->', selectedTabs)
              console.log('Visualization:', visualizationUrl)
              if (masksMapping.length === 0) {
                message.success('No masks detected!')
              } else {
                message.success('Image detection success')
              }

              // Handle the visualization image
              clearCanvas()
              setInpaintSnapShots(null)
              setInpaintSnapShotsID(0)
              handleImageUpload({ target: { files: [blob] } }, drawCanvas.current, setOriginImage, setInpaintSnapShots)
            })
            .catch((err) => {
              console.error('Failed to process image:', err)
              message.error('Failed to process image: ' + err.message)
            })
            .finally(() => {
              setImageIsUploading(false) // End loading
            })
        }
      }
    },
    [
      previewImage,
      clearCanvas,
      drawCanvas,
      inpaintSnapShots,
      inpaintSnapShotsID,
      setInpaintSnapShots,
      setInpaintSnapShotsID,
      setOriginImage,
      setLabels,
      setSelectedTabs,
      referenceImage,
    ],
  )

  const handleClosePreview = () => {
    setPreviewImage(null) // Just close the modal
    setCurrentImageIndex(0)
  }

  const handleDownloadImage = () => {
    handleDownloadPreview(previewImage) // Just close the modal
  }

  const scrollUp = () => {
    document.querySelector('.overflow-y-auto').scrollBy({ top: -100, behavior: 'smooth' }) // Scrolls up
  }

  const scrollDown = () => {
    document.querySelector('.overflow-y-auto').scrollBy({ top: 100, behavior: 'smooth' }) // Scrolls down
  }

  const handleCloseWidget = () => {
    setShowImageWidget(false) // State handler to toggle visibility
    setImages([])
  }

  const axiosInstance = axios.create({
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  })

  const setMaskSelectedTab = (item) => {
    const labelKey = Object.keys(item)[0]
    let updatedSelectedTabs = Array.isArray(selectedTabs) ? [...selectedTabs] : []

    // Set wheel mask selection
    if (labelKey === 'Front Wheel' || labelKey === 'Rear Wheel') {
      setIsWheelMaskSelected(true)
    } else {
      setIsWheelMaskSelected(false)
    }

    const itemId = JSON.stringify(item)

    // Check if this exact item is already selected
    const isAlreadySelected = updatedSelectedTabs.some((selected) => JSON.stringify(selected) === itemId)

    if (isAlreadySelected) {
      console.log('Removing item:', item)
      // Remove this specific instance
      updatedSelectedTabs = updatedSelectedTabs.filter((selected) => JSON.stringify(selected) !== itemId)
      removeMaskImage(drawCanvas.current, setLassos, setActiveIndex, item)
    } else {
      // Add this specific instance
      updatedSelectedTabs.push(item)
      applyMaskOverImage(drawCanvas.current, item[labelKey], item['control_points'], 0.3, setLassos, setActiveIndex)
    }

    setSelectedTabs(updatedSelectedTabs)
  }

  const handleUpload = useCallback(
    (e) => {
      const file = e.target.files[0]
      if (file) {
        setImageIsUploading(true) // Start loading
        setPreviewImage(null)
        setCurrentImageIndex(0)
        setIsWheelMaskSelected(false)
        const formData = new FormData()
        formData.append('image', file)

        // Call the API
        axiosInstance
          .post(detectAPI, formData)
          .then(async (res) => {
            const { label_mask_mapping, visualization } = res.data

            const masksMapping = label_mask_mapping.map(({ mask, label, control_points }) => {
              const binaryString = atob(mask)
              const len = binaryString.length
              const bytes = new Uint8Array(len)
              for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i)
              }
              const blob = new Blob([bytes], { type: 'image/png' })
              return { [partLabels.masks[label]]: URL.createObjectURL(blob), control_points }
            })

            console.log('masksMapping-->:', masksMapping)

            const binaryString = atob(visualization)
            const len = binaryString.length
            const bytes = new Uint8Array(len)
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryString.charCodeAt(i)
            }
            const visualizationBlob = new Blob([bytes], { type: 'image/png' })
            const visualizationUrl = URL.createObjectURL(visualizationBlob)

            setLabels(masksMapping.map((item) => item))
            console.log('labels--->', labels)
            console.log('selected label--->', selectedTabs)
            console.log('Visualization:', visualizationUrl)
            if (masksMapping.length === 0) {
              message.success('No masks detected!')
            } else {
              message.success('Image detection success')
            }

            // Handle the visualization image
            clearCanvas()
            setInpaintSnapShots(null)
            setInpaintSnapShotsID(0)
            handleImageUpload(e, drawCanvas.current, setOriginImage, setInpaintSnapShots)
          })
          .catch((err) => {
            console.error('Failed to process image:', err)
            message.error('Failed to process image: ' + err.message)
          })
          .finally(() => {
            setImageIsUploading(false) // End loading
          })
      }
    },
    [clearCanvas, drawCanvas, setInpaintSnapShots, setInpaintSnapShotsID, setOriginImage, setLabels, setSelectedTabs],
  )

  const handleDownload = useCallback(() => {
    handleImageDownload(originImage)
  }, [originImage])

  const handleInpaint = useCallback(() => {
    viewReset(drawCanvas.current)
    const formData = getInpaintFormData(drawCanvas.current, originImage)
    setIsLoading(true)
    axios
      .post(inpaintAPI, formData, { responseType: 'blob' })
      .then((res) => {
        const inpaintImage = new Image()
        inpaintImage.crossOrigin = 'Anonymous'
        inpaintImage.src = URL.createObjectURL(res.data)
        console.log(inpaintImage.src)
        inpaintImage.onload = () => {
          const newImage = new fabric.Image(inpaintImage)
          clearCanvas()
          storeInpaintSnapShots(
            newImage,
            inpaintSnapShots,
            inpaintSnapShotsID,
            setInpaintSnapShots,
            setInpaintSnapShotsID,
          )
          setOriginImage(newImage)
          message.success('success')
          setIsLoading(false)
        }
      })
      .catch((err) => {
        message.error(err.message)
        setIsLoading(false)
      })
  }, [
    clearCanvas,
    drawCanvas,
    inpaintSnapShots,
    inpaintSnapShotsID,
    originImage,
    setInpaintSnapShots,
    setInpaintSnapShotsID,
    setIsLoading,
    setOriginImage,
  ])

  // Initialize image states with loading placeholders
  const initializeImageLoaders = (numberOfImages) => {
    const imageLoaders = Array.from({ length: numberOfImages }, () => ({
      src: null,
      loading: true,
    }))
    setImages(imageLoaders)
  }

  const getSignedUrls = async (files) => {
    const data = { files }
    return makeAuthorizedRequest('/upload/getsignedurl', 'POST', data, idToken)
  }

  const registerUploadedFile = async (file, token) => {
    const data = { files: [file] }
    return makeAuthorizedRequest('/upload/db', 'POST', data, token)
  }

  const createDesignEntry = async (style, promptText, token) => {
    try {
      const res = await makeAuthorizedRequest(
        '/design',
        'POST',
        {
          name: style,
          description: promptText,
          projectId,
          status: 'Active',
        },
        token,
      )
      message.success('Design entry created successfully')
      return res
    } catch (err) {
      message.error('Failed to create design entry: ' + err.message)
    }
  }

  // function convertBase64ToBlob(base64, mimeType) {
  //   const binaryData = atob(base64)
  //   const len = binaryData.length
  //   const bytes = new Uint8Array(len)
  //   for (let i = 0; i < len; i++) {
  //     bytes[i] = binaryData.charCodeAt(i)
  //   }
  //   return new Blob([bytes], { type: mimeType })
  // }

  const handleInpaintProcess = useCallback(
    async (
      promptText,
      drawingInfluence,
      numberOfImages,
      style,
      denoise,
      upscaleFactor,
      useReferenceImage,
      blob,
      ipWeight,
      changeBackgroundOnly,
      changeSelectedAreaOnly,
      activeTab,
    ) => {
      if (activeTab === '3d') {
        setIsGenerating3D(true) // Show loading indicator when 3D model generation starts
      }
      setIsProcessing(true)
      viewReset(drawCanvas.current)
      const formData = getInpaintFormData(drawCanvas.current, originImage, useReferenceImage, blob)
      const isMask = isMaskDrawn(drawCanvas.current)
      console.log('isMask--->', isMask)
      const prompts = JSON.stringify({
        positive_prompt: promptText,
        drawingInfluence,
        numberOfImages,
        style,
        denoise,
        upscaleFactor,
        isMask,
        useReferenceImage,
        ipWeight,
        changeSelectedAreaOnly,
        changeBackgroundOnly,
        activeTab,
      })
      setPromptText(promptText)
      setImages([])
      formData.append('prompt', prompts)

      if (activeTab === '3d') {
        try {
          const res = await axios.post(processAPI, formData, { responseType: 'json' })
          console.log('3D Model Generation Response:', res.data)

          const images = res.data.images // Assuming 'images' is the key containing the encoded images
          const decodedImages = images.map((img) => `data:image/jpeg;base64,${img}`) // Constructing the source for <img>
          // Update UI with decoded images immediately
          const updatedImages = decodedImages.map((src) => ({
            src,
            loading: false,
            designId: null,
            attachmentId: null,
          }))

          await clearDB('StudioDB', 'Images')
          await saveToDB('StudioDB', 'Images', { id: 'images', data: updatedImages })

          const glbModels = res.data.models.map((model) => ({
            filename: model.filename,
            data: model.data,
          }))

          glbModels.forEach((model) => {
            handleAddModelToViewport(model.data)
          })
        } catch (err) {
          message.error('Failed to process image: ' + err.message)
        } finally {
          setIsLoading(false)
          setIsProcessing(false)
          setIsGenerating3D(false)
        }
      } else {
        setShowImageWidget(true)
        initializeImageLoaders(numberOfImages) // Initialize loading state for images

        try {
          const res = await axios.post(processAPI, formData, { responseType: 'json' })
          const images = res.data.images // Assuming 'images' is the key containing the encoded images
          const decodedImages = images.map((img) => `data:image/jpeg;base64,${img}`) // Constructing the source for <img>

          // Update UI with decoded images immediately
          const updatedImages = decodedImages.map((src) => ({
            src,
            loading: false,
            designId: null,
            attachmentId: null,
          }))
          setImages(updatedImages)
          message.success('Image processed successfully')

          // Create design entry
          const designId = await createDesignEntry(`${style} - ${activeTab}`, promptText, idToken)

          console.log('designId ---> ', designId)

          // Prepare files data for signed URLs
          const files = decodedImages.map((src, index) => ({
            originalFileName: `generated_image_${index}.jpeg`,
            fileType: 'image/jpeg',
            size: src.length * (3 / 4), // approximate size calculation
          }))

          // Get signed URLs
          const signedUrlsResponse = await getSignedUrls(files, idToken)
          const signedUrls = signedUrlsResponse.files

          // Upload images to signed URLs in parallel and register them
          const uploadPromises = signedUrls.map(({ fileName, originalFileName, fileType, signedUrl, size }, index) => {
            const file = new Blob([Uint8Array.from(atob(decodedImages[index].split(',')[1]), (c) => c.charCodeAt(0))], {
              type: 'image/jpeg',
            })
            return uploadToSignedUrl(signedUrl, file).then(async () => {
              const attachmentRes = await registerUploadedFile(
                {
                  designId: designId.id,
                  fileName, // Adjust this according to the actual response
                  originalFileName,
                  fileType,
                  size,
                },
                idToken,
              )

              // Update the images state with designId and attachmentId
              setImages((prevImages) =>
                prevImages.map((img, imgIndex) =>
                  imgIndex === index
                    ? {
                        ...img,
                        designId: designId.id,
                        attachmentId: attachmentRes.id,
                      }
                    : img,
                ),
              )
            })
          })

          Promise.allSettled(uploadPromises).then((results) => {
            results.forEach((result, index) => {
              if (result.status === 'fulfilled') {
                console.log(`Upload and register ${index} succeeded`)
              } else {
                console.error(`Upload and register ${index} failed`, result.reason)
              }
            })
          })
        } catch (err) {
          setShowImageWidget(false)
          initializeImageLoaders(0)
          message.error('Failed to process image: ' + err.message)
        } finally {
          setIsLoading(false)
          setIsProcessing(false)
        }
      }
    },
    [
      setIsProcessing,
      setShowImageWidget,
      setImages,
      viewReset,
      getInpaintFormData,
      drawCanvas,
      originImage,
      processAPI,
    ],
  )

  const handleDeblur = useCallback(() => {
    viewReset(drawCanvas.current)
    const formData = getDeblurFormData(originImage)
    setIsLoading(true)
    axios
      .post(deblurAPI, formData, { responseType: 'blob' })
      .then((res) => {
        const inpaintImage = new Image()
        inpaintImage.crossOrigin = 'Anonymous'
        inpaintImage.src = URL.createObjectURL(res.data)
        inpaintImage.onload = () => {
          const newImage = new fabric.Image(inpaintImage)
          clearCanvas()
          storeInpaintSnapShots(
            newImage,
            inpaintSnapShots,
            inpaintSnapShotsID,
            setInpaintSnapShots,
            setInpaintSnapShotsID,
          )
          setOriginImage(newImage)
          message.success('success')
          setIsLoading(false)
        }
      })
      .catch((err) => {
        message.error(err.message)
        setIsLoading(false)
      })
  }, [
    clearCanvas,
    drawCanvas,
    inpaintSnapShots,
    inpaintSnapShotsID,
    originImage,
    setInpaintSnapShots,
    setInpaintSnapShotsID,
    setIsLoading,
    setOriginImage,
  ])

  const undoDisabled = useMemo(() => {
    return !canUndo(snapShotsID)
  }, [snapShotsID])

  const executeUndo = useCallback(() => {
    if (undoDisabled) return
    const curState = undoCommand(snapShots, snapShotsID, setSnapShotsID)
    restoreSnapShot(curState, drawCanvas.current, setLassos, setActiveIndex, setDrawType)
  }, [drawCanvas, setActiveIndex, setDrawType, setLassos, setSnapShotsID, snapShots, snapShotsID, undoDisabled])

  const redoDisabled = useMemo(() => {
    return !canRedo(snapShots, snapShotsID)
  }, [snapShots, snapShotsID])

  const executeRedo = useCallback(() => {
    if (redoDisabled) return
    const curState = redoCommand(snapShots, snapShotsID, setSnapShotsID)
    restoreSnapShot(curState, drawCanvas.current, setLassos, setActiveIndex, setDrawType)
  }, [drawCanvas, redoDisabled, setActiveIndex, setDrawType, setLassos, setSnapShotsID, snapShots, snapShotsID])

  const backDisabled = useMemo(() => {
    return !canUndo(inpaintSnapShotsID)
  }, [inpaintSnapShotsID])

  const executeInpaintBack = useCallback(() => {
    if (backDisabled) return
    const curState = undoCommand(inpaintSnapShots, inpaintSnapShotsID, setInpaintSnapShotsID)
    restoreInpaintSnapShot(curState, setOriginImage)
  }, [backDisabled, inpaintSnapShots, inpaintSnapShotsID, setInpaintSnapShotsID, setOriginImage])

  const forwardDisabled = useMemo(() => {
    return !canRedo(inpaintSnapShots, inpaintSnapShotsID)
  }, [inpaintSnapShots, inpaintSnapShotsID])

  const executeInpaintForward = useCallback(() => {
    if (forwardDisabled) return
    const curState = redoCommand(inpaintSnapShots, inpaintSnapShotsID, setInpaintSnapShotsID)
    restoreInpaintSnapShot(curState, setOriginImage)
  }, [forwardDisabled, inpaintSnapShots, inpaintSnapShotsID, setInpaintSnapShotsID, setOriginImage])

  const scrollLeft = () => {
    if (tabsContainerRef.current) {
      tabsContainerRef.current.scrollBy({ left: -100, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    if (tabsContainerRef.current) {
      tabsContainerRef.current.scrollBy({ left: 100, behavior: 'smooth' })
    }
  }

  // useEffect for handling keyboard shortcuts
  useEffect(() => {
    console.log('selected -->', currentProject)
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key.toLowerCase() === 'z') {
        if (event.shiftKey) {
          executeRedo()
        } else {
          executeUndo()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [executeUndo, executeRedo])

  // Refactoring the useEffect for the reference image upload
  useEffect(() => {
    if (referenceImage) {
      const reader = new FileReader()
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: referenceImage.type })
        setBlob(blob)
      }
      reader.readAsArrayBuffer(referenceImage)
    }
  }, [referenceImage])

  const handleAddModelToViewport = useCallback(
    (glbBase64) => {
      const binaryData = atob(glbBase64)
      const bytes = new Uint8Array(binaryData.length)
      for (let i = 0; i < binaryData.length; i++) {
        bytes[i] = binaryData.charCodeAt(i)
      }

      const blob = new Blob([bytes], { type: 'model/gltf-binary' })
      const url = URL.createObjectURL(blob)

      console.log('✅ Passing Model to Studio:', url)

      // Additional data for ImagePreview
      const imagePreviewData = {
        currentIndex: currentImageIndex, // Pass the current index
        progress, // Pass the progress value
      }

      // Navigate to 3D Studio Page with the Model URL and ImagePreview data
      navigate(`/studio?modelUrl=${encodeURIComponent(url)}`, { state: imagePreviewData })
    },
    [
      navigate,
      images,
      currentImageIndex,
      originImage,
      progress,
      handleClosePreview,
      handleDownloadImage,
      setCurrentImageIndex,
    ],
  )

  return (
    <div>
      {isGenerating3D && activeTab === '3d' && (
        <div className="loading-overlay">
          <div className="loader-container">
            <div className="loader-spinner"></div>
            <h2 className="loader-text">Generating 3D Model...</h2>
          </div>
        </div>
      )}

      {viewMode === '2d' ? (
        <div id="fabricCanvasContainer" style={{ width: '100%', height: '100%' }}>
          <div className="p-2 bg-gray rounded-xl shadow-xl">
            {isImageUploading && (
              <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
                <Spin size="large" />
              </div>
            )}
            {/* Upload Image Box */}
            <div
              className={`fixed inset-0 flex items-center justify-center z-40 ${
                originImage === null ? 'flex' : 'hidden'
              }`}
            >
              <div className="p-2 bg-gray rounded-xl shadow-xl">
                <InputButton
                  onChange={handleUpload}
                  tailWindStyle="hover:bg-gray-800/[0.2] hover:border-gray-800/[0.8] border-sky-800/[0.4] border-2 rounded-xl border-dashed bg-gray-100"
                >
                  <div className="flex flex-col space-y-24 mx-40 my-40">
                    <FileImageOutlined className="text-6xl" />
                    <div className="text-2xl">Upload an image from your file system</div>
                  </div>
                </InputButton>
              </div>
            </div>
            {/* Tabs above */}
            {labels.length > 0 && (
              <div
                className={`fixed inset-x-0 top-2 flex justify-center items-center px-2 z-50 pointer-events-none ${
                  originImage === null ? 'hidden' : 'flex'
                }`}
              >
                <button onClick={scrollLeft} className="px-2 py-1 bg-gray-200 rounded-l-lg pointer-events-auto">
                  {'<'}
                </button>
                <div
                  className="flex overflow-hidden h-7 w-1/2 px-2 rounded-lg"
                  style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
                >
                  <style>
                    {`
          .no-scrollbar::-webkit-scrollbar {
            display: none;
          }
        `}
                  </style>
                  <div ref={tabsContainerRef} className="flex overflow-x-auto no-scrollbar space-x-2">
                    {labels.map((item, index) => {
                      const labelKey = Object.keys(item)[0]
                      const isSelected = selectedTabs.some(
                        (selected) => JSON.stringify(selected) === JSON.stringify(item),
                      )

                      return (
                        <Tooltip key={index} title={labelKey} placement="bottom">
                          <button
                            className={`flex-none bg-gray rounded-lg border-2 shadow-lg pointer-events-auto tab cursor-pointer text-center px-4 ${
                              isSelected ? 'border-b-2 border-black' : 'font-normal'
                            }`}
                            onClick={() => setMaskSelectedTab(item)}
                          >
                            {labelKey}
                          </button>
                        </Tooltip>
                      )
                    })}
                  </div>
                </div>
                <button onClick={scrollRight} className="px-2 py-1 bg-gray-200 rounded-r-lg pointer-events-auto">
                  {'>'}
                </button>
              </div>
            )}

            {/* Tool Bar */}
            <div
              className={`fixed inset-x-0 bottom-2 flex justify-between px-4 z-50 pointer-events-none ${
                originImage === null ? `hidden` : `flex`
              }`}
            >
              {/* Left Bottom Toolbar - Basic Tools */}
              <div className="bg-gray p-2 border-2 rounded-lg shadow-2xl flex space-x-2 pointer-events-auto">
                <ToolButton
                  disabledPopConfirm={false}
                  className="transition duration-150 ease-in-out transform hover:scale-110 shadow-lg hover:shadow-xl"
                  onClick={clearCanvas}
                  icon={faRotate}
                  title="clear"
                />
                <ToolButton
                  onClick={executeUndo}
                  className="transition duration-150 ease-in-out transform hover:scale-110 shadow-lg hover:shadow-xl"
                  icon={faReply}
                  title="undo"
                  disabled={undoDisabled}
                />
                <ToolButton
                  onClick={executeRedo}
                  className="transition duration-150 ease-in-out transform hover:scale-110 shadow-lg hover:shadow-xl"
                  icon={faShare}
                  title="redo"
                  disabled={redoDisabled}
                />
              </div>

              {/* Center Bottom Toolbar - Main Interaction Tools */}
              <div className="bg-gray p-2 rounded-lg border-2 shadow-lg flex space-x-2 pointer-events-auto">
                <InputButton
                  disabledPopConfirm={false}
                  tailWindStyle={`hover:bg-gray-100 px-2 py-1 rounded-lg`}
                  title="upload"
                  onChange={handleUpload}
                >
                  {useMemo(
                    () => (
                      <FontAwesomeIcon icon={faUpload} />
                    ),
                    [],
                  )}
                </InputButton>
                <ToolButton onClick={handleDownload} icon={faDownload} title="download" />
                <ToolButton onClick={() => viewReset(drawCanvas.current)} icon={faEye} title="view reset" />

                <ToolButton
                  isActive={drawType === DRAW_TYPE.FREE_DRAW}
                  onClick={useCallback(
                    () =>
                      drawType === DRAW_TYPE.FREE_DRAW
                        ? setDrawType(DRAW_TYPE.NORMAL)
                        : setDrawType(DRAW_TYPE.FREE_DRAW),
                    [drawType, setDrawType],
                  )}
                  icon={faEraser}
                  title="eraser"
                />

                <div style={{ width: 100 }}>
                  <Slider
                    tooltip={{ placement: 'bottom' }}
                    defaultValue={10}
                    max={90}
                    min={5}
                    value={penWidth}
                    onChange={useCallback(
                      (v) => {
                        setPenWidth(v)
                      },
                      [setPenWidth],
                    )}
                  />
                </div>

                <ToolButton
                  isActive={drawType === DRAW_TYPE.LASSO_DRAW}
                  onClick={useCallback(() => {
                    if (drawType === DRAW_TYPE.LASSO_DRAW) {
                      setDrawType(DRAW_TYPE.NORMAL)
                    } else {
                      setDrawType(DRAW_TYPE.LASSO_DRAW)
                      setActiveIndex({ lassoIndex: -1, pointIndex: -1 })
                    }
                  }, [drawType, setActiveIndex, setDrawType])}
                  icon={faDrawPolygon}
                  title="lasso drawing"
                />

                <ToolButton
                  isActive={drawType === DRAW_TYPE.LASSO_DRAG_POINTS}
                  onClick={useCallback(
                    () =>
                      drawType === DRAW_TYPE.LASSO_DRAG_POINTS
                        ? setDrawType(DRAW_TYPE.NORMAL)
                        : setDrawType(DRAW_TYPE.LASSO_DRAG_POINTS),
                    [drawType, setDrawType],
                  )}
                  icon={faHand}
                  title="lasso dragging"
                />
                {/* 
                    NEW 2D <-> 3D TOGGLE BUTTON
                    (replace the faCodeCompare icon or pick any icon you like)
                  */}
                <ToolButton
                  onClick={useCallback(() => setViewMode(viewMode === '2d' ? '3d' : '2d'), [viewMode, setViewMode])}
                  icon={faCodeCompare}
                  title="Toggle 2D/3D"
                />
              </div>
              {/* Right Bottom Toolbar - Advanced Tools */}
              <div className="bg-gray p-2 rounded-lg border-2 shadow-lg flex space-x-2 pointer-events-auto">
                <ToolButton
                  onClick={executeInpaintBack}
                  icon={faArrowLeft}
                  title="image back"
                  disabled={backDisabled}
                  disabledPopConfirm={false}
                />

                <ToolButton
                  onClick={executeInpaintForward}
                  icon={faArrowRight}
                  title="image forward"
                  disabled={forwardDisabled}
                  disabledPopConfirm={false}
                />

                <ToolButton
                  onMouseDown={useCallback(() => {
                    setShowOriginImage(true)
                  }, [setShowOriginImage])}
                  onMouseUp={useCallback(() => {
                    setShowOriginImage(false)
                  }, [setShowOriginImage])}
                  icon={faCodeCompare}
                  title="image comparision"
                  disabled={backDisabled}
                />

                <ToolButton
                  onClick={handleInpaint}
                  icon={faWandMagicSparkles}
                  title="inpaint"
                  disabledPopConfirm={false}
                />

                <ToolButton onClick={handleDeblur} icon={faGem} title="deblur" disabledPopConfirm={false} />
              </div>
              {/* Right Top Prompt Bar - Input and Generation */}
              <div
                className={`fixed top-2 right-2 bg-gray-100 text-gray p-4 rounded-lg border-2 shadow-lg z-50 transition-all duration-300 pointer-events-auto`}
              >
                <h2 className="text-lg font-bold mb-4 rounded-lg bg-gray-200 p-2">Create</h2>
                <div className="border-b border-gray mb-4"></div>
                <label className="block text-sm font-medium mb-1">Style:</label>
                <RichDropdown options={styles} setStyle={setStyle} />
                <div className="border-b border-gray mb-4"></div>
                <div className="flex mb-4 border-b border-white">
                  <button
                    className={`mr-1 py-2 px-6 rounded-t-lg ${
                      activeTab === 'render' ? 'bg-gray-400' : 'bg-gray-200 text-black'
                    }`}
                    onClick={() => setActiveTab('render')}
                  >
                    <label className="block text-base mb-1">Render</label>
                  </button>
                  <button
                    className={`mr-1 py-2 px-4 rounded-t-lg ${
                      activeTab === 'refine' ? 'bg-gray-400' : 'bg-gray-200 text-black'
                    }`}
                    onClick={() => setActiveTab('refine')}
                  >
                    <label className="block text-base mb-1">Refine</label>
                  </button>
                  <button
                    className={`mr-1 py-2 px-4 rounded-t-lg ${
                      activeTab === '3d' ? 'bg-gray-400' : 'bg-gray-200 text-black'
                    }`}
                    onClick={() => setActiveTab('3d')}
                  >
                    <label className="block text-base mb-1">3D</label>
                  </button>
                </div>
                {activeTab === 'render' && (
                  <RenderTab
                    promptText={promptText}
                    setPromptText={setPromptText}
                    drawingInfluence={drawingInfluence}
                    setDrawingInfluence={setDrawingInfluence}
                    drawingInfluenceMax={drawingInfluenceMax}
                    drawingInfluenceMin={drawingInfluenceMin}
                    denoise={denoise}
                    setDenoise={setDenoise}
                    numberOfImages={numberOfImages}
                    setNumberOfImages={setNumberOfImages}
                    creativityMax={creativityMax}
                    creativityMin={creativityMin}
                    setChangeSelectedAreaOnly={setChangeSelectedAreaOnly}
                    changeSelectedAreaOnly={changeSelectedAreaOnly}
                    setChangeBackgroundOnly={setChangeBackgroundOnly}
                    changeBackgroundOnly={changeBackgroundOnly}
                  />
                )}
                {activeTab === 'refine' && (
                  <RefineTab upscaleFactor={upscaleFactor} setUpscaleFactor={setUpscaleFactor} />
                )}
                {activeTab === '3d' && <ThreeDTab get3DWeight={get3DWeight} set3DWeight={set3DWeight} />}
                <div className="flex justify-between items-center">
                  {/* Ref Image Section */}
                  <div className="w-1/2 pr-2">
                    <div className="flex items-center">
                      <label className="text-sm font-medium mr-2">Reference Image:</label>
                      <Tooltip title="Enable or disable the use of a reference image for processing.">
                        <FontAwesomeIcon icon={faInfoCircle} className="text-gray-500" />
                      </Tooltip>
                    </div>
                    <div className="flex items-center mt-1">
                      <Switch
                        checked={referenceImage !== null}
                        onChange={() => {
                          if (useReferenceImage) {
                            setUseReferenceImage(false) // Disable reference image
                            setReferenceImage(null) // Clear the reference image
                            message.info('Reference image disabled.')
                          } else {
                            setUseReferenceImage(true) // Enable reference image
                            message.info('Reference image enabled.')
                          }
                        }}
                        size="small"
                        className="mr-1"
                      />
                      <span className="text-xs">Enable</span>
                    </div>
                    {useReferenceImage && referenceImage && (
                      <div className="mt-2">
                        <p className="text-xs text-gray-500">Selected: {referenceImage.name}</p>
                      </div>
                    )}
                  </div>

                  {/* Weight Slider Section */}
                  <div className="w-1/2 pr-2">
                    <div className="flex items-center">
                      <label className="text-sm font-medium mr-2">Weight:</label>
                      <Tooltip title="Adjust the influence of the reference image on the output.">
                        <FontAwesomeIcon icon={faInfoCircle} className="text-gray-500" />
                      </Tooltip>
                    </div>
                    <div className="mt-1">
                      <Slider
                        className="w-full"
                        tooltip={{ placement: 'top' }}
                        step={0.1}
                        max={weightMax}
                        min={weightMin}
                        value={ipWeight}
                        onChange={useCallback(
                          (v) => {
                            setIPWeight(v)
                            message.info(`Weight set to ${v.toFixed(1)}`)
                          },
                          [setIPWeight],
                        )}
                        marks={{
                          [weightMin]: {
                            style: { fontSize: '10px' },
                            label: 'Min',
                          },
                          [weightMax]: {
                            style: { fontSize: '10px' },
                            label: 'Max',
                          },
                        }}
                      />
                      <p className="text-xs text-gray-500 text-right mt-1">Current: {ipWeight.toFixed(1)}</p>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() =>
                    handleInpaintProcess(
                      promptText,
                      drawingInfluence,
                      numberOfImages,
                      style,
                      denoise,
                      upscaleFactor,
                      referenceImage !== null,
                      blob,
                      ipWeight,
                      changeBackgroundOnly,
                      changeSelectedAreaOnly,
                      activeTab,
                    )
                  }
                  disabled={isProcessing || !promptText.trim() || (useReferenceImage && !referenceImage)}
                  className={`mt-2 w-full flex items-center justify-center space-x-2 p-2 rounded-md ${
                    isProcessing || !promptText.trim() || (useReferenceImage && !referenceImage)
                      ? 'opacity-50 cursor-not-allowed'
                      : 'bg-gradient-to-r from-blue-500 to-purple-500 hover:from-purple-500 hover:to-blue-500'
                  }`}
                >
                  {isProcessing ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faPlay} />}
                  <span className="text-sm font-extrabold">Generate</span>
                </button>
              </div>

              {/* Reference Image Section */}
              {useReferenceImage && (
                <div
                  className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 pointer-events-auto"
                  style={{ backdropFilter: 'blur(5px)' }}
                >
                  <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-lg font-bold">Upload Reference Image</h2>
                      <button
                        onClick={() => setUseReferenceImage(false)} // Close the modal
                        className="text-red-500 hover:text-red-700"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                    <div
                      {...getRootProps()}
                      className="border-dashed border-2 border-gray-400 p-4 rounded-lg cursor-pointer text-center"
                    >
                      <input {...getInputProps()} />
                      <p className="text-sm">Drag & drop a reference image here, or click to select one</p>
                      <FontAwesomeIcon icon={faUpload} size="2x" className="text-gray-500 mt-2" />
                      {referenceImage && (
                        <div className="mt-4">
                          <p className="text-sm font-bold">Selected file:</p>
                          <p className="text-sm">{referenceImage.name}</p>
                          <img
                            src={URL.createObjectURL(referenceImage)}
                            alt="Reference Preview"
                            className="mt-2 w-full h-auto rounded-lg shadow-md"
                          />
                        </div>
                      )}
                    </div>
                    <div className="mt-4 flex justify-end">
                      <button
                        onClick={() => setUseReferenceImage(false)} // Close the modal
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          if (referenceImage) {
                            message.success('Reference image uploaded successfully!')
                            setUseReferenceImage(false) // Keep the toggle enabled
                          } else {
                            message.error('Please select a reference image first.')
                          }
                        }}
                        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                          !referenceImage ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={!referenceImage}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/* Left Top Bar - Image generated */}
              {showImageWidget && (
                <div
                  className="fixed top-2 left-2 bg-gray-100 p-2 rounded-lg border-2 shadow-lg z-50 transition-all duration-300 pointer-events-auto"
                  style={{ width: '200px', maxHeight: '80vh' }}
                >
                  <div className="flex justify-between items-center">
                    <h2 className="text-base font-bold rounded-lg bg-gray-200 p-2">Generated Images</h2>
                    <button onClick={handleCloseWidget} className="text-xl p-1">
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                  </div>
                  <div className="relative items-center justify-center overflow-hidden mt-4">
                    {images.length > visibleCount && (
                      <button className="relative top-0 left-0 text-black" onClick={scrollUp} style={{ zIndex: 20 }}>
                        <FontAwesomeIcon icon={faChevronUp} />
                      </button>
                    )}
                    <div className="overflow-y-auto" style={{ maxHeight: 'calc(70vh - 40px)' }}>
                      <div className="grid grid-cols-1 gap-4">
                        {images.map((img, index) => (
                          <div
                            key={index}
                            className="relative w-full flex items-center justify-center overflow-hidden rounded-lg"
                            style={{ height: '150px', border: '1px solid #ccc' }} // Set fixed height here
                          >
                            {img.loading ? (
                              <div className="relative w-full h-full flex items-center justify-center overflow-hidden rounded-lg">
                                <img
                                  src="./car.jpg" // Path to a sample blurred image
                                  className="absolute top-0 left-0 w-full h-full object-cover blur-moving"
                                  alt="Loading image"
                                />
                                <div className="absolute inset-0 flex justify-center items-center">
                                  <Spin size="large" />
                                </div>
                              </div>
                            ) : (
                              <img
                                src={img.src}
                                alt={`Generated Image ${index}`}
                                className="w-full h-full object-cover rounded-lg hover:scale-105 shadow-xl transition-all duration-300 ease-in-out cursor-pointer"
                                onClick={() => handleImageClick(img.src, index)}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    {images.length > visibleCount && (
                      <button
                        className="relative centre bottom-0 text-black shadow-lg"
                        onClick={scrollDown}
                        style={{ zIndex: 10 }}
                      >
                        <FontAwesomeIcon icon={faChevronDown} />
                      </button>
                    )}
                    {/* Additional Options for Wheel Mask */}
                    {isWheelMaskSelected && (
                      <div className="mt-4">
                        <button
                          onClick={() => setIsWheelMaskModalVisible(true)}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                          More Wheel Options
                        </button>
                      </div>
                    )}

                    {/* Wheel Mask Modal */}
                    <WheelMaskOptions
                      visible={isWheelMaskModalVisible}
                      onClose={() => setIsWheelMaskModalVisible(false)}
                      onGenerate={(promptText) => {
                        setStyle('wheel')
                        setDenoise(90 / 100)
                        handleInpaintProcess(
                          promptText,
                          drawingInfluence,
                          numberOfImages,
                          style,
                          denoise,
                          upscaleFactor,
                          useReferenceImage,
                          blob,
                          ipWeight,
                          changeBackgroundOnly,
                          changeSelectedAreaOnly,
                          activeTab,
                        )
                        setIsWheelMaskModalVisible(false)
                        // Your logic for generating based on wheel mask prompt
                      }}
                    />
                    {previewImage && (
                      <ImagePreview
                        images={images}
                        currentIndex={currentImageIndex}
                        originalSrc={originImage}
                        onConfirm={handleAddImageToCanvas} // Now this is just referenced, not invoked
                        onCancel={handleClosePreview}
                        onDownload={handleDownloadImage}
                        setCurrentIndex={setCurrentImageIndex}
                        progress={progress}
                        setPreviewImage={setPreviewImage}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ThreeDViewport modelUrl={modelUrl} />
      )}
    </div>
  )
}

export default ToolBar
