import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaList, FaThLarge, FaPlus, FaFilter, FaEdit } from 'react-icons/fa'
import CreateProjectForm from '../modals/CreateProjectForm'
import { useAuth } from '../../contexts/auth-context'
import makeAuthorizedRequest from '../../core/api'
import { Spin, Modal } from 'antd'
import { useProject } from '../../contexts/project-context'
import { useNavigate } from 'react-router-dom' // Import useNavigate
import './ProjectGallery.css'

const ProjectGallery = () => {
  const { idToken } = useAuth()
  const [projects, setProjects] = useState([])
  const [designs, setDesigns] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [view, setView] = useState('mosaic')
  const [currentPage, setCurrentPage] = useState(1)
  const [refresh, setRefresh] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedDesign, setSelectedDesign] = useState(null)
  const { setCurrentProject } = useProject()
  const [inProgressProjectId, setInProgressProjectId] = useState(null)
  const itemsPerPage = 6
  const navigate = useNavigate() // Initialize navigate
  const [designsLoading, setDesignsLoading] = useState(false)

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true)
      try {
        const response = await makeAuthorizedRequest('/project', 'GET', null, idToken)
        setProjects(Array.isArray(response) ? response : [])
      } catch (error) {
        setProjects([])
        console.error('Error fetching projects:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchProjects()
  }, [idToken, refresh])

  const clearDesignsCache = (projectId) => {
    localStorage.removeItem(`attachments_project_${projectId}`)
  }

  const fetchDesignsWithAttachments = async (projectId, token) => {
    const cacheKey = `attachments_project_${projectId}`
    const cachedData = localStorage.getItem(cacheKey)

    if (cachedData) {
      // Return cached data if present
      return JSON.parse(cachedData)
    }

    // Fetch from API if not cached
    const response = await makeAuthorizedRequest(`/design/project?projectId=${projectId}`, 'GET', null, token)

    // Cache the response for future requests
    localStorage.setItem(cacheKey, JSON.stringify(response))

    return response
  }

  useEffect(() => {
    if (selectedProject) {
      const fetchDesigns = async () => {
        setDesignsLoading(true) // start loading
        try {
          const response = await fetchDesignsWithAttachments(selectedProject.id, idToken)
          setDesigns(response)
        } catch (error) {
          console.error('Error fetching designs:', error)
          setDesigns([])
        } finally {
          setDesignsLoading(false) // end loading
        }
      }

      fetchDesigns()
    }
  }, [selectedProject, idToken])

  const lastProjectIndex = currentPage * itemsPerPage
  const firstProjectIndex = lastProjectIndex - itemsPerPage
  const totalItems = projects.length ? Math.ceil(projects.length / itemsPerPage) : 0
  const currentProjects = projects.slice(firstProjectIndex, lastProjectIndex)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const handleCardClick = (project) => {
    setSelectedProject(project)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const handleBackToList = () => {
    setSelectedProject(null)
  }

  const handleEditProject = (project) => {
    setSelectedProject(project)
    setIsOpen(true)
  }

  const handleUpdateProject = (updatedProject) => {
    setSelectedProject(updatedProject)
    setProjects(projects.map((project) => (project.id === updatedProject.id ? updatedProject : project)))

    // Clear cache upon update
    clearDesignsCache(updatedProject.id)

    setRefresh(!refresh)
  }

  const handleStartProject = (project) => {
    if (inProgressProjectId === project.id) {
      setCurrentProject(null)
      setInProgressProjectId(null)
    } else {
      setCurrentProject(project)
      setInProgressProjectId(project.id)
      localStorage.setItem(project.id, JSON.stringify(project))
      navigate(`/edit/${project.id}`) // Navigate to the EditPage with project id
    }
  }

  const handleDesignClick = (design) => {
    setSelectedDesign(design)
  }

  const handleCloseDesignModal = () => {
    setSelectedDesign(null)
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="mt-5 px-5 lg:px-20 max-w-7xl mx-auto flex-grow">
        <div className="flex justify-between items-center mb-3">
          <h4 className="text-2xl font-bold">Projects</h4>
          {!selectedProject && (
            <div className="flex space-x-4 items-center">
              <button
                onClick={() => setView('list')}
                className={`p-2 rounded-md ${
                  view === 'list' ? 'bg-blue-700 text-white' : 'bg-blue-600 text-white hover:bg-blue-800'
                }`}
              >
                <FaList size={20} />
              </button>
              <button
                onClick={() => setView('mosaic')}
                className={`p-2 rounded-md ${
                  view === 'mosaic' ? 'bg-blue-700 text-white' : 'bg-blue-600 text-white hover:bg-blue-800'
                }`}
              >
                <FaThLarge size={20} />
              </button>
              <button
                onClick={() => setIsOpen(true)}
                className="p-2 rounded-md bg-green-600 text-white hover:bg-green-800"
              >
                <FaPlus size={20} />
              </button>
              <button className="p-2 rounded-md bg-gray-600 text-white hover:bg-gray-800">
                <FaFilter size={20} />
              </button>
            </div>
          )}
        </div>
        <CreateProjectForm
          isOpen={isOpen}
          setIsOpen={handleCloseModal}
          project={selectedProject}
          onSave={handleUpdateProject}
        />
        {isLoading ? (
          <div className="flex justify-center items-center flex-grow">
            <Spin size="large" />
          </div>
        ) : selectedProject ? (
          <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-xl overflow-hidden">
            <img src={selectedProject.thumbnail} alt={selectedProject.name} className="w-full h-60 object-cover" />
            <div className="p-6">
              <button
                onClick={handleBackToList}
                className="text-sm font-medium text-blue-600 hover:underline mb-4 inline-flex items-center"
              >
                ← Back to Projects
              </button>
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-bold text-gray-800">{selectedProject.name}</h3>
                <button
                  onClick={() => handleEditProject(selectedProject)}
                  className="p-2 rounded-md bg-yellow-500 text-white hover:bg-yellow-600"
                >
                  <FaEdit size={20} />
                </button>
              </div>
              <p className="text-gray-600 mb-3">{selectedProject.description}</p>
              <div className="text-sm text-gray-500 space-y-1 mb-4">
                <p>
                  <strong>Status:</strong> {selectedProject.status}
                </p>
                <p>
                  <strong>Priority:</strong> {selectedProject.priority}
                </p>
                <p>
                  <strong>Deadline:</strong> {new Date(selectedProject.deadline).toLocaleString()}
                </p>
                <p>
                  <strong>Budget:</strong> ${selectedProject.budget}
                </p>
              </div>
              <h4 className="text-lg font-semibold text-gray-700 mb-2">Team Members</h4>
              <ul className="list-disc list-inside text-gray-600 mb-4">
                {selectedProject.teamMembers.map((member) => (
                  <li key={member.userId}>{member.role}</li>
                ))}
              </ul>

              <h4 className="text-lg font-semibold text-gray-700 mb-2">Attachments</h4>

              {designsLoading ? (
                <div className="flex justify-center py-10">
                  <Spin size="large" tip="Loading designs..." />
                </div>
              ) : (
                <div className="flex flex-wrap gap-4">
                  {designs.map((design, index) => (
                    <div
                      key={index}
                      className="design-thumbnail w-32 h-32 border rounded-lg overflow-hidden cursor-pointer"
                      onClick={() => handleDesignClick(design)}
                    >
                      <div className="thumbnail-stack">
                        {design.attachments.slice(0, 3).map((attachment, i) => (
                          <img
                            key={i}
                            src={attachment.downloadUrl}
                            alt={attachment.originalFileName}
                            className={`thumbnail-image ${i === 0 ? 'top' : ''} ${i === 1 ? 'middle' : ''} ${
                              i === 2 ? 'bottom' : ''
                            }`}
                          />
                        ))}
                      </div>
                      <div className="design-name-overlay">
                        <span className="text-center">{design.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {selectedProject.status === 'Active' && (
                <button
                  onClick={() => handleStartProject(selectedProject)}
                  className={`mt-4 px-4 py-2 rounded-md text-white ${
                    inProgressProjectId === selectedProject.id
                      ? 'bg-yellow-500 hover:bg-yellow-600'
                      : 'bg-blue-500 hover:bg-blue-600'
                  }`}
                >
                  {inProgressProjectId === selectedProject.id ? 'Continue' : 'Start'}
                </button>
              )}
            </div>
          </div>
        ) : (
          <>
            {projects.length === 0 ? (
              <div className="flex-grow flex justify-center items-center">
                <p className="text-xl text-gray-500">No projects found</p>
              </div>
            ) : (
              <div className="max-w-7xl mx-auto px-5 py-8">
                <h2 className="text-3xl font-bold text-gray-800 mb-6">Project Gallery</h2>

                <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                  {currentProjects.map((project) => (
                    <div
                      key={project.id}
                      className="bg-white rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer overflow-hidden"
                      onClick={() => handleCardClick(project)}
                    >
                      <img src={project.thumbnail} alt={project.name} className="w-full h-44 object-cover" />
                      <div className="p-4">
                        <h3 className="text-lg font-semibold text-gray-800 truncate">{project.name}</h3>
                        <div className="flex items-center justify-between mt-2">
                          <span
                            className={`inline-block text-xs font-medium px-2 py-1 rounded ${
                              project.status === 'Active'
                                ? 'bg-green-100 text-green-800'
                                : project.status === 'Completed'
                                ? 'bg-blue-100 text-blue-800'
                                : 'bg-yellow-100 text-yellow-800'
                            }`}
                          >
                            {project.status}
                          </span>
                          <span className="text-xs font-medium text-gray-500">Priority: {project.priority}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {!selectedProject && (
        <div className="flex justify-center mt-8 mb-5">
          <div className="flex space-x-1">
            {Array.from({ length: totalItems }, (_, i) => i + 1).map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={`px-4 py-2 text-sm rounded-md ${
                  number === currentPage ? 'bg-blue-600 text-white' : 'bg-white text-gray-800 hover:bg-blue-200'
                }`}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      )}

      <Modal visible={!!selectedDesign} onCancel={handleCloseDesignModal} footer={null} width={850} centered>
        {selectedDesign && (
          <div className="p-4">
            <h3 className="text-xl font-bold text-gray-800 mb-4">{selectedDesign.name}</h3>
            <h1 className="text-gray-800 mb-4">{selectedDesign.description}</h1>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              {selectedDesign.attachments.map((attachment, index) => (
                <a
                  key={index}
                  href={attachment.downloadUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="overflow-hidden rounded-lg shadow hover:shadow-lg transition-shadow"
                >
                  <img
                    src={attachment.downloadUrl}
                    alt={attachment.originalFileName}
                    className="w-full h-36 object-cover"
                  />
                </a>
              ))}
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

ProjectGallery.propTypes = {
  projects: PropTypes.array.isRequired,
}

export default ProjectGallery
