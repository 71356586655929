import React, { useState } from 'react'
/* eslint-disable react/prop-types */
const RichDropdown = ({ options, setStyle }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(options[0])

  const toggleDropdown = () => setIsOpen(!isOpen)
  const handleOptionClick = (option) => {
    setSelectedOption(option)
    setStyle(option.value)
    setIsOpen(false)
  }

  return (
    <div className="relative">
      <div
        className="p-2 mb-4 text-sm rounded-md border border-gray-300 shadow-sm cursor-pointer"
        onClick={toggleDropdown}
      >
        <div className="flex items-center">
          <img src={selectedOption.image} alt="" className="w-10 h-10 mr-2" />
          <div className="flex-1 min-w-0">
            {' '}
            {/* Ensures the container takes the remaining space */}
            <div className="font-bold truncate">{selectedOption.title}</div>{' '}
            {/* Truncates the title if it is too long */}
            <div
              className="text-xs text-gray-500 w-40 overflow-hidden text-ellipsis"
              style={{ display: '-webkit-box', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical' }}
            >
              {selectedOption.description}
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <ul className="absolute w-full border border-gray-300 rounded-md bg-white shadow-lg z-10">
          {options.map((option) => (
            <li
              key={option.value}
              className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
              onClick={() => handleOptionClick(option)}
            >
              <img src={option.image} alt="" className="w-10 h-10 mr-2" />
              <div className="w-full mb-4 text-sm cursor-pointer">
                <div>{option.title}</div>
                <div className="text-xs text-gray-500">{option.description}</div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default RichDropdown
